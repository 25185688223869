/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

/**
 * Generate a sequence of numbers.
 * @param {number} start The number to start at
 * @param {number} length The size of the sequence
 */
const sequence = (start, length) => {
    const keys = Array(length).keys()
    const seq = [...keys].map((index) => {
        return index + start
    })

    return seq;
};

export { sequence };
