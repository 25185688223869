/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { CmsConfig } from "cms/config";
import { Logtail } from "@logtail/browser";
import { logsConfig } from "cms/utils/log-config";
// import getCurrentLine from 'get-current-line'

/**
 *
 *   Logging is from BetterStack. It requires a source token to run. A free account is possible.
 *     https://betterstack.com
 *     - a token is required to run. with it you can see logs in a web console
 *     - you can then enable console logs using 'REACT_APP_ENABLE_CONSOLE_LOG=true'
 *     - see Config for more env vars
 */

let Logger;
const ConsoleLogger = console;
const source_token = CmsConfig.Logging.SourceToken;
Logger = new Logtail(source_token, logsConfig);
const alertColour = "color: orange; background-color: black;";
const LOCAL_LOGGING_ONLY = process.env.REACT_APP_LOG_SOURCE_TOKEN === undefined;
if (LOCAL_LOGGING_ONLY) {
    console.log(
        "%c Cloud Service Logging is not available due to configuration. Source Token error is expected when no source token is added to env vars. ",
        alertColour
    );
    console.log("%c Logging is from BetterStack.  A free account is possible.  https://betterstack.com ", alertColour);

    // set up the Logger as console
    Logger = ConsoleLogger;
    ConsoleLogger.flush = async () => {};
} else {
    if (CmsConfig.Logging.enableConsole) {
        // this means you want just the console
        Logger = ConsoleLogger;
        ConsoleLogger.flush = async () => {};
        console.log("%c Cloud Service Logging was disabled in favour of local only console logging. ", alertColour);
    } else {
        const getCallerLine = () => {
            const e = new Error();
            const regex = /\((.*):(\d+):(\d+)\)$/;
            let match = regex.exec(e.stack.split("\n")[2]);

            // firefox workaround
            if (match === null) {
                const regex2 = /(.*):(\d+):(\d+)$/;
                match = regex2.exec(e.stack.split("\n")[1]);
            }

            return {
                filepath: match[1],
                line: match[2],
                column: match[3],
            };
        };
        // const getStack = () => {
        //     const e = new Error()
        //     return e.stack
        // }

        // this means you want Cloud Logs
        const developerLog = (log) => {
            // const location = getCurrentLine()
            const location = getCallerLine();
            if (log.level === "error") {
                // const error = log.error
                // const { message, stack } = error || {}
                // const error_message = `[${log.dt.toISOString()}] [${log.level}] ${log.message} ${message} \n${stack}`
                // const error_message = `[${log.dt.toISOString()}] 🌆 [${log.level.toUpperCase()}] ${log.message} ${message}`
                // ConsoleLogger.error(error_message, log.error, log.context)
                // ConsoleLogger.error(log.message, log.error)
            } else if (log.level === "warn") {
                // ConsoleLogger.warn(log.message, log.context)
            } else {
                log.message = `[${log.dt.toISOString()}]  🌆 [${log.level.toUpperCase()}] ${log.message}`;
            }
            log.location = location;
            return log;
        };
        Logger.use(developerLog);
    }
}
export { Logger, ConsoleLogger };

// better wrapping to investigate
// https://stackoverflow.com/questions/13815640/a-proper-wrapper-for-console-log-with-correct-line-number?noredirect=1&lq=1
// import getCurrentLine from 'get-current-line'
