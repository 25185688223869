import React, { Children, useContext, useState } from "react";

const MyDNVContext = React.createContext();

export const useMyDNVContext = () => useContext(MyDNVContext);

export const MyDNVProvider = ({ children }) => {
  let [bookmarks, setBookmark] = useState();
  let value = { bookmarks, setBookmark };

  return (<MyDNVContext.Provider value = {value}>
    {children}
    </MyDNVContext.Provider>
  );
};

