/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { isObject } from "cms/utils/empty-exists";

export const neigbourhoods = [
    { type: "All", value: "All", label: "- Any -"},
    { type: 'Deep Cove', value: 'Deep Cove', label: "Deep Cove" },
    { type: 'Delbrook / Upper Lonsdale', value: 'Delbrook / Upper Lonsdale', label: "Delbrook / Upper Lonsdale" },
    { type: 'Lionsgate / Lower Capilano', value: 'Lionsgate / Lower Capilano', label: "Lionsgate / Lower Capilano" },
    { type: 'Lynn Creek', value: 'Lynn Creek', label: "Lynn Creek" },
    { type: 'Lynn Valley', value: 'Lynn Valley', label: "Lynn Valley" },
    { type: 'Maplewood', value: 'Maplewood', label: "Maplewood" },
    { type: 'Seymour', value: 'Seymour', label: "Seymour" },
    { type: 'Upper Capilano', value: 'Upper Capilano', label: "Upper Capilano" },
];

export const getNeighbourhoods = () => {
    return neigbourhoods;
};

export const news_category = [
    {value: "All", label: "- Any -"},
    {value: "Council", label: "Council"},
    {value: "Development", label: "Development"},
    {value: "District Hall", label: "District Hall"},
    {value: "Press release", label: "Press release"},
    {value: "Projects", label: "Projects"},
    {value: "Public safety", label: "Public safety"},
    {value: "Services", label: "Services"},
    {value: "Traffic", label: "Traffic"}
];

export const commmittee_categories = [
    {value: "All", label: "- Any -"},
    {value: "Arts and recreation", label: "Arts and recreation"},
    {value: "Building, development, and transportation", label: "Building, development, and transportation"},
    {value: "Environment", label: "Environment"},
    {value: "Finance and tax", label: "Finance and tax"},
    {value: "Local government and administration", label: "Local government and administration"},
    {value: "Social planning", label: "Social planning"},
];

export const event_categories = [
    {value: "All", label: "- Any -"},
    {value: "Mayor and Council appearances", label: "Mayor and Council appearances"},
    {value: "Council meetings and public hearings", label: "Council meetings and public hearings"},
    {value: "Volunteer Opportunity", label: "Volunteer Opportunity"},
    {value: "Open House", label: "Open House"},
    {value: "Performance", label: "Performance"},
    {value: "Workshop", label: "Workshop"},
    {value: "Public Gathering", label: "Public Gathering"},
];

export const meeting_categories = [
    {value: "All", label: "- Any -"},
    {value: "Council workshops", label: "Council workshops"},
    {value: "Council regular meetings", label: "Council regular meetings"},
    {value: "Council special meetings", label: "Council special meetings"},
    {value: "Public hearings & meetings", label: "Public hearings & meetings"},
    {value: "Committee meetings", label: "Committee meetings"},
];

export const story_categories = [
    {value: "All", label: "- Any -", path: "/dnv-stories"},
    {value: "Community involvement", label: "Community involvement", path: "/dnv-stories/community-involvement"},
    {value: "Economy and finance", label: "Economy and finance", path: "/dnv-stories/economy-and-finance"},
    {value: "Environment and sustainability", label: "Environment and sustainability", path: "/dnv-stories/environment-and-sustainability"},
    {value: "Fire and Rescue Services", label: "Fire and Rescue Services", path: "/dnv-stories/fire-and-rescue-services"},
    {value: "Growth and development", label: "Growth and development", path: "/dnv-stories/growth-and-development"},
    {value: "Housing", label: "Housing", path: "/dnv-stories/housing-stories"},
    {value: "Parks and green spaces", label: "Parks and green spaces", path: "/dnv-stories/parks-and-green-spaces"},
    {value: "Projects and infrastructure", label: "Projects and infrastructure", path: "/dnv-stories/projects-and-infrastructure"},
    {value: "Streets and transportation", label: "Streets and transportation", path: "/dnv-stories/streets-and-transportation"},
];

export const bylaw_categories = [
    {value: "All", label: "- Any -"},
    {value: "Animals", label: "Animals"},
    {value: "Building and development", label: "Building and development"},
    {value: "Business", label: "Business"},
    {value: "Enforcement/nuisance abatement", label: "Enforcement/nuisance abatement"},
    {value: "Environment", label: "Environment"},
    {value: "Finance and taxes", label: "Finance and taxes"},
    {value: "Local Government and administration", label: "Local Government and administration"},
];

export const devapp_categories = [
    {value: "All", label: "- Any -"},
    {value: "Residential Level 1: Rural Residential", label: "Residential Level 1: Rural Residential"},
    {value: "Residential Level 2: Detached Residential", label: "Residential Level 2: Detached Residential"},
    {value: "Residential Level 3: Attached Residential", label: "Residential Level 3: Attached Residential"},
    {value: "Residential Level 4: Transition Multifamily", label: "Residential Level 4: Transition Multifamily"},
    {value: "Residential Level 5: Low Density Apartment", label: "Residential Level 5: Low Density Apartment"},
    {value: "Residential Level 6: Medium Density Apartment", label: "Residential Level 6: Medium Density Apartment"},
    {value: "Commercial Residential Mixed Use Level 1", label: "Commercial Residential Mixed Use Level 1"},
    {value: "Commercial Residential Mixed Use Level 2", label: "Commercial Residential Mixed Use Level 2"},
    {value: "Commercial Residential Mixed Use Level 3", label: "Commercial Residential Mixed Use Level 3"},
    {value: "Commercial", label: "Commercial"},
    {value: "Institutional", label: "Institutional"},
    {value: "Light Industrial Artisan", label: "Light Industrial Artisan"},
    {value: "Industrial", label: "Industrial"},
    {value: "Light Industrial Commercial", label: "Light Industrial Commercial"},
    {value: "Light Industrial Commercial Mixed Use - Innovation District", label: "Light Industrial Commercial Mixed Use - Innovation District"},
    {value: "Light Industrial Residential Mixed Use - Innovation District", label: "Light Industrial Residential Mixed Use - Innovation District"},
    {value: "Parks, Open Space, and Natural Areas", label: "Parks, Open Space, and Natural Areas"},
];

export const permits_categories = [
    {value: "All", label: "- Any -"},
    {value: "Animals", label: "Animals"},
    {value: "Building, trades, mechanical", label: "Building, trades, mechanical"},
    {value: "Business", label: "Business"},
    {value: "Development", label: "Development"},
    {value: "Environmental", label: "Environmental"},
    {value: "Fire", label: "Fire"},
    {value: "Parks", label: "Parks"},
    {value: "Streets and transportation", label: "Streets and transportation"},
];

export const dpa_categories = permits_categories;

export const licence_categories = permits_categories;

export const all_categories = [
    { type: 'model.news', data: news_category },
    { type: 'model.committee', data: commmittee_categories },
    { type: 'model.event', data: event_categories },
    { type: 'model.meeting', data: meeting_categories },
    { type: 'model.story', data: story_categories },
    { type: 'model.dpa', data: dpa_categories },
    { type: 'model.licence', data: licence_categories },
    { type: 'model.bylaw', data: bylaw_categories },
    { type: 'model.permit', data: permits_categories },
    // { type: 'model.development-application', data: devapp_categories },
    { type: 'model.development-application', data: neigbourhoods },
];

export const getCategory = (type) => {
    const result = all_categories.find((element) => element.type === type);
    let categories = [];
    if (isObject(result)) {
        categories = result.data;
    }
    return categories;
};

export const all_category_names = [
    { type: 'model.news', name: 'category', label: 'Category' },
    { type: 'model.committee', name: 'category', label: 'Category' },
    { type: 'model.event', name: 'category', label: 'Category' },
    { type: 'model.meeting', name: 'meeting_category', label: 'Meeting Category' },
    { type: 'model.story', name: 'category', label: 'Category' },
    { type: 'model.story', name: 'category', label: 'Category' },
    { type: 'model.dpa', name: 'category', label: 'Category' },
    { type: 'model.licence', name: 'category', label: 'Category' },
    { type: 'model.development-application', name: 'neighbourhood', label: 'Neighbourhood' },
];

export const getCategoryName = (type) => {
    const result = all_category_names.find((element) => element.type === type);
    let name = 'category';
    if (isObject(result)) {
        name = result.name;
    }
    return name;
};

export const getCategoryLabel = (type) => {
    const result = all_category_names.find((element) => element.type === type);
    let name = 'Category';
    if (isObject(result)) {
        name = result.label;
    }
    return name;
};
