/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { solid, brands } from 'icons'
const { faEnvelope, faXmark, faCopy } = solid
const { faXTwitter, faLinkedinIn, faFacebookF } = brands

const platforms = [
    {
        type: 'copy',
        label: "Copy Link",
        icon: faCopy,
        handler: (page) => {
            const { url } = page || {}
            navigator.clipboard.writeText(url);
        }
    },
    {
        type: 'email',
        label: "Email",
        icon: faEnvelope,
        handler: (page) => {
            const { title, url } = page || {}
            const message = 'Check out this page from the DNV that might be useful to you: \n\n' +
                // `<a href="${url}">${title}</a>`
                `${title}\n${url}`
            const subject = encodeURIComponent(`${title} - District of North Vancouver`)
            const body = encodeURIComponent(message)
            const mailtoUrl = `mailto:?subject=${subject}&body=${body}`
            window.open(mailtoUrl, "_blank", "noopener,noreferrer")
        }
    },
    {
        type: 'twitter',
        label: "X",
        icon: faXTwitter,
        handler: (page) => {
            const { title, url } = page || {}
            const params = `text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`
            const twitterShareUrl = `https://x.com/intent/tweet?${params}`
            window.open(twitterShareUrl, "_blank", "noopener,noreferrer")
        }
    },
    {
        type: 'facebook',
        label: "Facebook",
        icon: faFacebookF,
        handler: (page) => {
            const { url } = page || {}
            const params = `u=${encodeURIComponent(url)}`
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?${params}`;
            window.open(facebookShareUrl, "_blank", "noopener,noreferrer");
        }
    },
    {
        type: "linkedin",
        label: "LinkedIn",
        icon: faLinkedinIn,
        handler: (page) => {
            const { title, description, url, source } = page || {}
            const params = `mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}&source=${encodeURIComponent(source)}`
            const linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${params}`;
            window.open(linkedInShareUrl, "_blank", "noopener,noreferrer");
        }
    },
]
const defaultPlatform = {
    type: "default",
    label: "",
    icon: faXmark,
    handler: () => {}
}

export const getPlatformConfig = (type) => {
    let platform = platforms.find((elements) =>
        elements.type === type
    )
    return platform ? platform : defaultPlatform
}
