/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { isArrayNotEmpty } from "cms/utils/empty-exists";
import { Logger } from "cms/utils/logger";

export const getFields = (model) => {
    const fields = {};
    for (const field of model.fields) {
        const name = field.name;
        fields[name] = field;
    }
    return fields;
};

export const getField = (model, field_name) => {
    for (const field of model.fields) {
        const name = field.name;
        if (name === field_name) {
            return field;
        }
    }
};

export const getFirstFieldValue = (model, fieldName) => {
    // const introValue = fields?.find((subItem) => subItem.name === 'short_intro')?.values[0]?.value;
    const field = getField(model, fieldName);
    const values = getValues(field);
    if (isArrayNotEmpty(values)) {
        return values[0];
    }
};

// export const getRichText = (model) => {
//     // const introValue = fields?.find((subItem) => subItem.name === 'short_intro')?.values[0]?.value;
//     const field = getField(model,'short_intro');
//     const values = getValues(field);
//     if (isArrayNotEmpty(values)) {
//         return values[0];
//     }
// };

export const getJsonList = (field) => {
    try {
        if (field) {
            if (!field.list) {
                // error not a list
                Logger.error("JSON was not a list");
                return;
            }
            const jsons = [];
            for (const val of field.values) {
                const value = val.value;
                const data = JSON.parse(value);
                jsons.push(data);
            }
            return jsons;
        }
    } catch (error) {
        Logger.error(error);
        Logger.error("JSON could not be parsed.", field);
        return [];
    }
};

export const getJson = (field) => {
    try {
        if (field) {
            if (field.list) {
                Logger.error("JSON was a list. use getJSONList().");
                return;
            }
            const jsons = [];
            for (const val of field.values) {
                const { value } = val;
                const data = JSON.parse(value);
                jsons.push(data);
            }
            if (isArrayNotEmpty(jsons)) {
                const json = jsons[0];
                return json;
            }
        }
    } catch (error) {
        Logger.error(error);
        Logger.error("JSON could not be parsed.", field);
        return {};
    }
};

export const getValue = (field) => {
    try {
        if (!field) {
            return;
        }
        const values = [];
        for (const val of field.values) {
            const value = val.value;
            values.push(value);
        }
        // FIXME: list property is not present
        if (field?.list) {
            return values;
        } else {
            return values[0];
        }
    } catch (error) {
        Logger.error(error);
        Logger.error("Field 'Value' read failed.", field);
    }
};

export const getValues = (field) => {
    try {
        if (!field) {
            return;
        }
        const values = [];
        for (const val of field.values) {
            const value = val.value;
            values.push(value);
        }
        return values;
    } catch (error) {
        Logger.error(error);
        Logger.error("Field 'Value' read failed.", field);
    }
};

export const isValueEmpty = (field) => {
    try {
        let isEmpty = true;
        if (!field) {
            return isEmpty;
        }
        for (const val of field.values) {
            const value = val.value;
            if (value) {
                isEmpty = false;
            }
        }
        return isEmpty;
    } catch (error) {
        Logger.error(error);
        Logger.error("isValueEmpty: Field 'Value' read failed.", field);
    }
};

export default {
    getValue,
    getJson
}
