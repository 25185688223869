/** @format */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { NavLink } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';

// import { useSimpliCity } from "cms/hooks/use-simplicity";
import { VerifyEmailAddress } from './verify-email-address';
import { WaitForEmailVerification } from './wait-for-email-verification';
import { WhenSignedIn, WhenSignedOut } from './sign_in_out_util';
import { Config } from 'config';
import { getAccessToken } from 'mydnv/utils/access-token';
import * as signInService from 'mydnv/services/sign-in-service';

import dayjs from 'dayjs';
import { useMyDNVContext } from 'mydnv/context/mydnv-context';

// TODO move to src\cms\config.js ? and parameterize
const requestScopes = {
    scopes: ['https://dnvorgb2c.onmicrosoft.com/dnv-b2c-sample-api/tasks.read'],
};

// const SignInRequest = {
//     scopes: requestScopes.scopes,
//     authority: "https://dnvorgb2c.b2clogin.com/dnvorgb2c.onmicrosoft.com/B2C_1_SignIn",
// };

const SignUpRequest = {
    scopes: requestScopes.scopes,
    authority: 'https://dnvorgb2c.b2clogin.com/dnvorgb2c.onmicrosoft.com/B2C_1_SignUp',
};

// const debug = (webpage, model, error) => {
//     console.log(webpage);
//     console.log(model);
//     console.log(error);
// };

const MyDnv = () => {
    // const { webpage, model, error } = useSimpliCity();
    const { instance, accounts, inProgress } = useMsal();
    // const [accessToken, setAccessToken] = useState("");
    const [dropdownToggleState, setDropdownToggleState] = useState(false);
    const dropdownBtnRef = useRef(null);
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [displayNameLoaded, setDisplayNameLoaded] = useState(false);
    const [emailVerified, setEmailVerified] = useState(true); // assume verified, otherwise, show modal
    const [requestVerificationEmailActionType, setRequestVerificationEmailActionType] = useState(null);

    const [isCheckYourEmailModalOpen, setIsCheckYourEmailModalOpen] = useState(false);
    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);
    const [emailVerificationModalMessage, setEmailVerificationModalMessage] = useState('');
    const [verifyEmailRequestBody, setVerifyEmailRequestBody] = useState(null);

    const account = useAccount(accounts[0] || {});

    const loginRequest = Config.MyDNV.loginRequest;
    const isLoggedIn = useIsAuthenticated();

    // const navigate = useNavigate();
    const storeKeyVerifyingEmailAddress = 'verifying-email-address';
    const storeKeySignOutSignUpTimestamp = 'sign-out-sign-up';

    const request = useMemo(() => {
        return {
            ...loginRequest,
            account: account,
        };
    }, [account, loginRequest]);

    const apiConfig = {
        apiUrl: `${Config.MyDNV.azureFnPath}/getMyDNVBookmarks`, // URL of your protected API
    };

    let {bookmarks, setBookmark} = useMyDNVContext();

    const ParseVerifyEmailRequestUrl = () => {
        if (window.location.search.length > 0) {
            const params = new URLSearchParams(window.location.search);
            const verifyEmailRequest = {
                account: params.get('account'),
                email: params.get('email'),
                verify: params.get('verify'),
            };

            if (verifyEmailRequest.account && verifyEmailRequest.email && verifyEmailRequest.verify) {
                const stringFormatRequest = JSON.stringify(verifyEmailRequest);
                localStorage.setItem(storeKeyVerifyingEmailAddress, stringFormatRequest);
                return stringFormatRequest;
            }
        }
        return null;
    };

    const handleModal = (action) => {
        switch (action) {
            case 'sign-out':
                handleSignOut();
                break;
            case 'sign-out-sign-up':
                localStorage.setItem('sign-out-sign-up', JSON.stringify({ timestamp: dayjs().valueOf() }));
                // Need to sign-out first... then go back to sign-up
                handleSignOut();
                break;
            case 'resend-verification-email':
                setRequestVerificationEmailActionType('user-request');
                break;
            case 'close-wait-for-email-verification-modal':
                setIsEmailVerificationModalOpen(false);
                setEmailVerificationModalMessage('');
                setVerifyEmailRequestBody(null);
                // TODO first post email confirmation dialog
                break;
            default:
                break;
        }
    };

    const handleSignIn = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });

        setDropdownToggleState(false);
    };
    const handleSignUp = () => {
        localStorage.removeItem(storeKeySignOutSignUpTimestamp);
        instance.loginRedirect(SignUpRequest).catch((e) => {
            console.error(e);
        });

        setDropdownToggleState(false);
    };
    const handleSignOut = () => {
        localStorage.removeItem(storeKeyVerifyingEmailAddress);
        instance
            .logoutRedirect({
                postLogoutRedirectUri: '/',
            })
            .catch((e) => {
                console.error(e);
            });

        setDropdownToggleState(false);
    };

    const goToAboutUs = () => {
        console.log('This is a placeholder for the button to take us to the about us page');
        setDropdownToggleState(false);
    };

    const handleRequestVerificationEmail = useCallback((args) => {
        signInService
            .postRequestVerificationEmail(args)
            .then((response) => {
                console.warn(response.data);
                // response
                //     .json()
                //     .then((verificationUrl) => {
                //         // DEBUG
                //         console.warn(verificationUrl);
                //     })
                //     .catch((e) => console.error(e));
            })
            .catch((e) => console.error(e));
    }, []);

    const handleVerifyEmailAddress = useCallback((args) => {
        signInService
            .postVerifyEmailAddress(args)
            .then((response) => {
                localStorage.removeItem(storeKeyVerifyingEmailAddress);

                if (408 === response.status) {
                    setEmailVerificationModalMessage('timeout');
                } else if (200 === response.status) {
                    getAccessToken(request, instance, handleUserIdentity, null, null);
                    setEmailVerified(true);
                    setEmailVerificationModalMessage('verified');
                }
            })
            .catch((e) => console.error(e));
    }, []);

    const handleUserIdentity = useCallback((args) => {
        signInService
            .getUserIdentity(args)
            .then((response) => {
                // response.json().then((userInfo) => {
                //     const dn =
                //         userInfo.first_Name +
                //         (userInfo.last_Name.length > 0 ? ` ${userInfo.last_Name.substring(0, 1)}.` : '');
                //     setDisplayName(dn);
                //     if (userInfo.emails.length > 0) {
                //         setEmail(userInfo.emails[0]);
                //     }

                //     setDisplayNameLoaded(true);
                // });
                const userInfo = response.data;
                const dn =
                    userInfo.first_Name +
                    (userInfo.last_Name.length > 0 ? ` ${userInfo.last_Name.substring(0, 1)}.` : '');
                setDisplayName(dn);
                if (userInfo.emails.length > 0) {
                    setEmail(userInfo.emails[0]);
                }
                setDisplayNameLoaded(true);

                if (403 === response.status) {
                    console.warn('User must verify email first.');
                    setEmailVerified(false);
                    setIsCheckYourEmailModalOpen(true);
                    setRequestVerificationEmailActionType('page-load');
                }
            })
            .catch((e) => console.error(e));
    }, []);

    const handleBookmarks = useCallback((args) => {
        fetch(apiConfig.apiUrl, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${args.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                response
                    .json()
                    .then((json) => {
                        setBookmark(JSON.stringify(json));
                    })
                    .catch((e) => {
                        localStorage.setItem('bookmarks', JSON.stringify([]));
                    });
            })
            .catch((e) => console.error(e));
    }, []);

    const handleButtonClickListener = (event) => {
        if (event.type === 'blur' && event.relatedTarget) {
            event.preventDefault();
        } else {
            setDropdownToggleState(!dropdownToggleState);
        }
    };

    useEffect(() => {
        const verifyEmailRequest = ParseVerifyEmailRequestUrl() || localStorage.getItem(storeKeyVerifyingEmailAddress);
        const signOutSignUpTimestamp = localStorage.getItem(storeKeySignOutSignUpTimestamp);

        if (isLoggedIn && inProgress === InteractionStatus.None) {
            if (!!verifyEmailRequest) {
                setIsEmailVerificationModalOpen(true);
                setEmailVerificationModalMessage('waiting');
                setVerifyEmailRequestBody(JSON.parse(verifyEmailRequest));
            } else if (!!signOutSignUpTimestamp) {
                handleSignOut();
            } else {
                // acquireToken(); //             getUserBookmarks(response.accessToken);
                getAccessToken(request, instance, handleUserIdentity, null, null);
                getAccessToken(request, instance, handleBookmarks, null, null);
            }
        } else if (!!signOutSignUpTimestamp) {
            handleSignUp();
        } else if (!!verifyEmailRequest) {
            handleSignIn();
        }
    }, []);

    useEffect(() => {
        if (!!verifyEmailRequestBody) {
            getAccessToken(request, instance, handleVerifyEmailAddress, { body: verifyEmailRequestBody }, null);
        }
    }, [verifyEmailRequestBody, handleVerifyEmailAddress, instance, request]);

    useEffect(() => {
        if (!!requestVerificationEmailActionType) {
            getAccessToken(
                request,
                instance,
                handleRequestVerificationEmail,
                { actionType: requestVerificationEmailActionType },
                null
            );
        }
    }, [requestVerificationEmailActionType, handleRequestVerificationEmail, instance, request]);

    const MyDNVButtonLabel = () => {
        return (
            <>
                MyDNV {dropdownToggleState && <FontAwesomeIcon icon={`fa-solid fa-circle-chevron-up`} />}
                {!dropdownToggleState && <FontAwesomeIcon icon={`fa-solid fa-circle-chevron-down`} />}
            </>
        );
    };

    const DisplayNameLoaded = () => {
        return (
            <>
                {emailVerified ? (
                    <>
                        {displayName} <FontAwesomeIcon icon={`fa-solid fa-user`} />
                    </>
                ) : (
                    <MyDNVButtonLabel />
                )}
            </>
        );
    };

    return (
        <>
            <div className='myDnvLoginButtonDropdown'>
                <WhenSignedIn>
                    <NavLink to='/myDNV' className='signedIn'>
                        {displayNameLoaded ? <DisplayNameLoaded /> : <CircularProgress size='20px' color='primary' />}
                    </NavLink>
                </WhenSignedIn>
                <WhenSignedOut>
                    <button
                        className={`dropBtn ${dropdownToggleState ? 'dropdownActive' : ''}`}
                        id='dropBtn'
                        ref={dropdownBtnRef}
                        onClick={handleButtonClickListener}
                        onBlur={handleButtonClickListener}
                    >
                        <MyDNVButtonLabel />
                    </button>
                </WhenSignedOut>

                <div className={`dropdownBtnContent ${dropdownToggleState ? 'show' : ''}`}>
                    <WhenSignedOut>
                        <button onClick={() => handleSignIn()}>Log in</button>
                        <button onClick={() => handleSignUp()}>Sign up</button>
                        <button onClick={() => goToAboutUs()}>About MyDNV</button>
                    </WhenSignedOut>
                </div>
                {/* <div onClick={() => setBookmark(getBookmarkUrl('[   {        "created_Date": "2025-01-29T17:57:51",        "order": 1,        "id": 16,        "guid": "99eb68a1-cf15-4b6b-88aa-7eaceb59a91a",        "title": "Work starts",        "published_Date": "2024-08-23T19:10:34",        "updated_Date": "2024-08-23T19:10:34"    }]'))}>Test</div> */}
            </div>

            <VerifyEmailAddress isOpen={isCheckYourEmailModalOpen} setModalOpen={handleModal} emailAddress={email} />

            <WaitForEmailVerification
                isOpen={isEmailVerificationModalOpen}
                setModalOpen={handleModal}
                message={emailVerificationModalMessage}
            />
        </>
    );
};

export { MyDnv };
