/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { LayoutSelector } from "layout/layout-selector";
import { CircularProgress } from "@mui/material";
import { ErrorLayout } from "cms/layout/error/data-error/error-fullwidth";
import { isTrue } from "../utils/empty-exists";

const LayoutWrapper = () => {

    let {
        webpage,
        model,
        seo,
        error,
        loading,
    } = useSimpliCity();

    const twCardType = seo?.tw_card_type ?? "summary_large_image";

    return (
        <>
            {seo &&
                <Helmet>
                    <link rel="canonical" href={seo?.url} />
                    <meta property="og:url" content={seo?.url} />
                    <meta name="twitter:url" content={seo?.url} />

                    <title>{seo?.title}</title>
                    <meta property="og:title" content={seo?.og_title} />
                    <meta name="twitter:title" content={seo?.tw_title} />

                    <meta name="description" content={seo?.description} />
                    <meta property="og:description" content={seo?.og_description} />
                    <meta name="twitter:description" content={seo?.tw_description} />

                    <meta name="og:type" content={seo?.og_type} />
                    <meta name="twitter:card" content={twCardType} />

                    <meta property="og:image" content={seo?.og_image} />
                    <meta name="twitter:image" content={seo?.tw_image} />
                </Helmet>
            }

            {/* Block Search indexing with noindex */}
            {isTrue(webpage?.exclude_from_search) &&
                <Helmet>
                    <meta name="robots" content="noindex"></meta>
                </Helmet>
            }

            {webpage && model &&
                <LayoutSelector />
            }

            {/* FIXME: no loader fires on page navigation; this controls the first load */}
            {/*{loading && !webpage &&*/}
            {/*    <SkeletonLayout />*/}
            {/*}*/}

            {/*  need a 404 page - also called from router error */}
            {error && !loading &&
                <ErrorLayout />
                // <DefaultError />
            }
            {/*{!error && !webpage &&*/}
            {loading &&
                <div style={{
                    width: '100%',
                    height: '70vh',
                    gap: '24px',
                    display: 'flex', flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '40px'
                }}>
                    <CircularProgress
                        style={{ color: '#003E43' }}  // in CSS var(--color-dnv-bg-and-border-dark-navy)
                    />
                </div>
            }
        </>
    );
};

LayoutWrapper.displayName = "LayoutWrapper";
export { LayoutWrapper };
