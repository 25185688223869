/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React, { useState, useMemo } from "react";
import { FeedbackHelpful } from "components/feedback-helpful";
import { CalendarFeedFilter } from "cms/shared/feeds/calendar";
import { ShareButton } from "cms/shared/share-button";
import { BookmarkButton } from "components/page-title/bookmark-button";
import { Config } from "config";
import { FollowedInfoCard } from "components/page-title/followed-infocard/followed-infocard";
import { WhenSignedIn } from "mydnv/components/sign-in/sign_in_out_util";

const Events = () => {
    // determines what message / style to show in 'info card' after follow / subscribe / bookmark buttotn clicked
    const [actionType, setActionType] = useState(null);

    const wpObj = useMemo(() => {
        return {
            guid: Config.MyDNV.webpageGuidsList.events,
            title: "Events",
            published_date: null,
            updated_date: null,
        };
    }, []);

    return (
        <main>
            <WhenSignedIn>
                {actionType !== null && (
                    <FollowedInfoCard type={actionType} handleDismiss={setActionType} subTopic={""} />
                )}
            </WhenSignedIn>
            <h1 style={{ marginTop: "16px" }}>Events</h1>
            <div className="nav-container justify-end">
                <div className="buttons-container">
                    <BookmarkButton setActionType={setActionType} wpObj={wpObj}></BookmarkButton>
                    <ShareButton className="share-btn-nav" />
                </div>
            </div>
            <div className="last-updated-rule"></div>
            <>
                <div>
                    <CalendarFeedFilter />
                </div>
            </>
            <FeedbackHelpful />
        </main>
    );
};

export { Events };
