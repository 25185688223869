import React from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "icons";

export const SidePanel = (props) => {
    const { title, isPaneOpen, closeHandler, isMobile, bookmarks } = props;
    return (
        <>
            <SlidingPane
                className="myDNV-bookmarks-pane"
                overlayClassName="myDNV-bookmarks-pane-overlay"
                isOpen={isPaneOpen}
                title={title}
                closeIcon={
                    <div>
                        <FontAwesomeIcon icon={solid.faXmark} />
                    </div>
                }
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    closeHandler();
                }}
                width={isMobile ? "95%" : "45%"}
            >
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>Page</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookmarks &&
                                bookmarks.map((b, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <a href={b.pageLink}>{b.title}</a>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                {/* TODO: update url */}
                <div className="manage-bookmarks">
                    <a href="/mydnv?t=bookmarks">Manage my bookmarks</a>
                </div>
            </SlidingPane>
        </>
    );
};
