/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { createBrowserRouter } from "react-router-dom";
import { redirectLoader } from "./redirect-loader";
import { RouterError } from "cms/layout/error/router-error";
import { ApplicationLayout } from "layout/application-layout";
import { ApplicationNewsLayout } from "layout/news-layout";
import { Home } from "pages/home";
import { Search } from "pages/search";
// import { Stories } from 'pages/story';
import { News } from "pages/news";
import { Events } from "pages/events";
import { Advisories } from "pages/advisories";
import { Alerts } from "pages/alerts";
import { Webpage } from "pages/webpage";
import { Licences } from "pages/licences";
import { EventSearch } from "pages/events-search";
// import { ApplicationStoryLayout } from 'layout/story-layout';
// import { ApplicationSidebarLayout } from 'layout/application-sidebar-layout';
// import { ApplicationEventLayout } from 'layout/event-layout';
// import { ApplicationStoryLayout } from 'layout/story-layout';

/**
 * Data enabled routes for the application.  This exists outside of the app itself
 * as they only need to be rendered once.
 */
export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <RouterError />,
    },

    // example of nested application layout structure
    {
        path: "/search",
        element: <ApplicationLayout crumbs={[{ path: "/", title: "Home" }, { title: "Search Results" }]} />,
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Search />,
            },
        ],
    },
    {
        path: "/events",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "Events" }]}
                customClass={"events-page"}
                selectedSection={"Events"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Events />,
            },
        ],
    },
    {
        path: "/news",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "News" }]}
                selectedSection={"News"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <News />,
            },
        ],
    },
    {
        path: "/advisories",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "Advisories" }]}
                selectedSection={"Advisories"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Advisories />,
            },
        ],
    },
    {
        path: "/alerts",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "Alerts" }]}
                selectedSection={"Alerts"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Alerts />,
            },
        ],
    },
    {
        path: "/licences",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "Licences" }]}
                selectedSection={"Licences"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <Licences />,
            },
        ],
    },
    {
        path: "/events/search",
        element: (
            <ApplicationNewsLayout
                crumbs={[{ path: "/", title: "Home" }, { title: "Events" }]}
                selectedSection={"Events"}
            />
        ),
        errorElement: <RouterError />,
        loader: redirectLoader,
        children: [
            {
                index: true,
                element: <EventSearch />,
            },
        ],
    },
    // {
    //     path: "/dnv-stories-old",
    //     element: <ApplicationStoryLayout crumbs={[{ path: "/", title: "Home" }, { title: "Stories" }]} />,
    //     errorElement: <RouterError />,
    //     loader: redirectLoader,
    //     children: [
    //         {
    //             path: "*",
    //             index: true,
    //             element: <Stories />,
    //         },
    //     ],
    // },

    // catch all for paths of any length
    // will need to handle its own 404 logic
    {
        path: "*",
        element: <Webpage />,
        errorElement: <RouterError />,
        loader: redirectLoader,
    },
]);
