/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { useRef, useState } from "react";
import {
    useFloating,
    autoUpdate,
    offset,
    // flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingArrow,
    useId,
    arrow,
} from "@floating-ui/react";
import { CmsConfig } from "cms/config";
import { Config } from "config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShareItem } from "./share-item";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { solid } from "icons";
const { faXmark, faShareNodes } = solid;

const ShareButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const { webpage, model, website } = useSimpliCity();
    // const { path } = webpage;
    let path = "";
    if (webpage) {
        path = webpage.path;
    } else {
        path = window.location.pathname;
    }
    const { title: source } = website;
    // const { title, description } = model;
    let title, description;
    if (model) {
        title = model.title;
        description = model.description;
    } else {
        title = document.title;
        const desc = window.location.pathname.replace("/", "");
        description = desc.charAt(0).toUpperCase() + desc.slice(1);
    }
    let site_url = CmsConfig.isPreview ? new URL(Config.SEO.url) : window.location;
    const { protocol, host } = new URL(site_url);
    const url = `${protocol}//${host}${path}`;
    const page = { title, description, url, source };

    const arrowRef = useRef(null);
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            // flip({ fallbackAxisSideDirection: "end" }),
            shift(),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const interactions = useInteractions([useClick(context), useDismiss(context), useRole(context)]);
    const headingId = useId();

    const closePopover = () => {
        setIsOpen(false);
    };

    const showCopyToast = () => {
        setShowToast(true);
        setIsOpen(false);
        setTimeout(() => {
            setShowToast(false);
        }, 2000);
    };
    const handleShareButtonClick = () => {
        setIsOpen((prev) => !prev);
        if (showToast) {
            // hide the toast if the user opens the popover again
            setShowToast(false);
        }
    };
    const active = isOpen ? " share-button-active" : "";

    return (
        <>
            <button
                ref={refs.setReference}
                {...interactions.getReferenceProps()}
                className={`share-button${active}`}
                aria-expanded={isOpen}
                aria-controls="share-button"
                aria-label="Share button to see share options"
                onClick={handleShareButtonClick}
            >
                Share
                <FontAwesomeIcon icon={faShareNodes} className="share-button-icon" aria-hidden="true" />
            </button>
            {isOpen && (
                <FloatingFocusManager context={context} modal={false}>
                    <div
                        className="share-popover"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        aria-labelledby={headingId}
                        id="share-popover"
                        {...interactions.getFloatingProps()}
                    >
                        <div className="popover-header">
                            <h2 className="popover-title" id={headingId}>
                                Share
                            </h2>
                            <button className="close-button" onClick={closePopover} aria-label="Close share options">
                                <FontAwesomeIcon icon={faXmark} style={{ color: "#0873A1" }} aria-hidden="true" />
                            </button>
                        </div>
                        <div className="popover-content">
                            <ul>
                                <ShareItem type="email" page={page} onClose={closePopover} />
                                <ShareItem type="twitter" page={page} onClose={closePopover} />
                                <ShareItem type="facebook" page={page} onClose={closePopover} />
                                <ShareItem type="linkedin" page={page} onClose={closePopover} />
                                <hr />
                                <ShareItem
                                    variant="primary"
                                    type="copy"
                                    page={page}
                                    onClick={showCopyToast}
                                    onClose={closePopover}
                                />
                            </ul>
                        </div>
                        <FloatingArrow
                            ref={arrowRef}
                            context={context}
                            fill={"#FAFAF9"}
                            stroke={"#919191"}
                            strokeWidth={1}
                        />
                    </div>
                </FloatingFocusManager>
            )}
            {showToast && (
                <div className="toast">
                    <span>Copied to clipboard</span>
                </div>
            )}
        </>
    );
};

export { ShareButton };
