/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { TabsBar } from "./tabs-bar";
import { Logger } from "cms/utils/logger";

const Tabs = (props) => {
    const { tabs = [], selected, setSelected, children, customContainerClassName } = props;

    // const [tabs, setTabs] = useState(_tabs)

    const selectTab = function (event, id) {
        event.preventDefault();
        Logger.debug(selected);
        setSelected(id);
    };

    return (
        <div className="panel-group tabs" data-group-type="tabs">
            <div className="website-tabs-bar">
                <TabsBar tabs={tabs} selectTab={selectTab} selected={selected} />
            </div>

            <div
                className={`website-tabs-container ${customContainerClassName != null ? customContainerClassName : ""}`}
            >
                {children}
            </div>
        </div>
    );
};

Tabs.displayName = "Tabs";
export { Tabs };
