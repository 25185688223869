import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "cms/auth/auth/hooks/useAuth";
import { fetchWebpage } from "mydnv/utils/fetchWebpageUtil";
import { solid } from "icons";
import { SidePanel } from "./side-panel";

import { WhenSignedIn } from "mydnv/components/sign-in/sign_in_out_util";
import { useMyDNVContext } from "mydnv/context/mydnv-context";

export const Bookmark = () => {
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [sortedBookmarks, setSortedBookmarks] = useState([]);
    const paneControl = () => {
        setIsPaneOpen(!isPaneOpen);
    };

    const { token } = useAuth();
    let { bookmarks } = useMyDNVContext();

    const sortBookmarks = (bookmarksToSort) => {
        return [...bookmarksToSort].sort((a, b) => a.order - b.order);
    };

    const handleBookmarkDetailEvaluations = useCallback(
        async (bookmarksToSort = JSON.parse(bookmarks)) => {
            let bookmarksSorted = [];
            for (const sortedOrderBookmark of sortBookmarks(bookmarksToSort)) {
                let webpageInfo = await fetchWebpage(token, sortedOrderBookmark.guid);
                bookmarksSorted.push({
                    ...sortedOrderBookmark,
                    pageLink: `${window.location.origin}${webpageInfo.path}`,
                });
            }
            // console.log(bookmarksSorted);
            setSortedBookmarks(bookmarksSorted);
        },
        [bookmarks, token]
    );

    useEffect(() => {
        if (bookmarks && bookmarks.length > 0) {
            handleBookmarkDetailEvaluations();
        }
    }, [bookmarks, handleBookmarkDetailEvaluations]);

    return (
        <>
            <WhenSignedIn>
                <button className="btn btn-bookmark" onClick={paneControl}>
                    <FontAwesomeIcon icon={solid.faBookmark} size={"lg"} />
                </button>
            </WhenSignedIn>
            <SidePanel
                title={"Bookmarks"}
                bookmarks={sortedBookmarks}
                isPaneOpen={isPaneOpen}
                closeHandler={paneControl}
                isMobile={false}
            ></SidePanel>
            
        </>
    );
};
