/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

export const formatBytes = (bytes, decimals = 1) => {
    if (!+bytes) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const calculateBytesByUnit = (maxFileSize, fileUnit) => {
    let fileSize = 0;
    if (fileUnit === "kb") {
        fileSize = Number(maxFileSize).valueOf() * 1024;
    } else if (fileUnit === "mb") {
        fileSize = Number(maxFileSize).valueOf() * 1024 * 1024;
    } else if (fileUnit === "gb") {
        fileSize = Number(maxFileSize).valueOf() * 1024 * 1024 * 1024;
    }
    return fileSize
};
