/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { PreviewGuard } from 'cms/auth/auth/components/preview-guard'
import { StageGuard } from 'cms/auth/auth/components/stage-guard'
import { PreviewBanner } from 'cms/auth/auth/components/preview-banner'
import { StageBanner } from 'cms/auth/auth/components/stage-banner'
import { LiveGuard } from 'cms/auth/auth/components/live-guard'

const EnvWrapper = (props) => {

    const { children } = props

    return (
        <>
            <PreviewGuard>
                <PreviewBanner>
                    {children}
                </PreviewBanner>
            </PreviewGuard>

            <StageGuard>
                <StageBanner>
                    {children}
                </StageBanner>
            </StageGuard>

            <LiveGuard>
                {children}
            </LiveGuard>

        </>
    )
}

export { EnvWrapper }
