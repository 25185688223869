/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

const Config = {
    Recollect: {
        script: { src: "https://assets.ca.recollect.net/api/widget.js?scripts=head" },
        style: {
            href: "https://recollect.a.ssl.fastly.net/0.11.1654194167/api/areas/DistNorthVancouver/services/waste/style/widget/logan/Default.css",
        },
    },
    Userway: {
        account: "unAfj7cv4G",
    },
    SEO: {
        url: "https://www.dnv.org",
        website_title: "District of North Vancouver",
        description:
            "This is the official website for the District of North Vancouver, in British Columbia, Canada. It contains information on District programs and services, property and development, government business, and more.",
        website_image: "https://images.dnv.org/images/dnv-air-deepcove.jpg",
        keywords: "District North Vancouver",
    },
    google_site_verification: "+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=",
    recaptcha_site_key: "6LenVeEpAAAAAHBfaB6YaiuxZewd_XN-5LkeT_2a",
    dateFormat: {
        date: "MMMM DD, YYYY",
        date_new: "dddd, MMM D",
        date_short: "ddd, MMM D",
        event_date: "dddd, MMM D",
        date_fullday: "dddd MMMM D, YYYY",
        date_fullday_with_time: "dddd MMMM D, YYYY h:mma",
        time: "h:mma",
        datetime: "MMMM DD, YYYY h:mma",
        date_short_no_year: "MMM D",
        event_date_month: "MMM",
        event_date_date: "D",
        date_shortday: "ddd MMMM D, YYYY",
        date_fullday_short: "ddd MMMM D, YYYY",
        date_no_dow: "MMMM D, YYYY",
    },

    MyDNV: {
        loginRequest: {
            scopes: [process.env.REACT_APP_MYDNV_LOGINREQUEST], // Add your API scopes
        },
        // azureFnPath: "https://cct1-am-mydnv-uat.azure-api.net/dnv-mydnv-function-app-api-uat",
        azureFnPath: process.env.REACT_APP_MYDNV_AZUREFNPATH,
        azureFnPathPublic: process.env.REACT_APP_MYDNV_AZUREFNPATH_PUBLIC,
        webpageGuidsList: {
            advisories: process.env.REACT_APP_MYDNV_WEBPAGEGUIDS_ADVISORIES,
            alerts: process.env.REACT_APP_MYDNV_WEBPAGEGUIDS_ALERTS,
            news: process.env.REACT_APP_MYDNV_WEBPAGEGUIDS_NEWS,
            stories: process.env.REACT_APP_MYDNV_WEBPAGEGUIDS_STORIES,
            events: process.env.REACT_APP_MYDNV_WEBPAGEGUIDS_EVENTS,
        },
        showSubscribeButtonList: process.env.REACT_APP_MYDNV_SHOW_SUBSCRIBE_BUTTONS,
        msal: {
            clientId: process.env.REACT_APP_MYDNV_MSAL_CLIENTID,
            redirectUri: process.env.REACT_APP_MYDNV_MSAL_REDIRECT,
            postLogoutRedirectUri: process.env.REACT_APP_MYDNV_MSAL_POST_REDIRECT,
            authority: process.env.REACT_APP_MYDNV_MSAL_AUTHORITY,
            knownAuthorities: process.env.REACT_APP_MYDNV_MSAL_KNOWN_AUTHORITIES,
            cache: {
                cacheLocation: "localStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        }
    },
};

export { Config };
