/** @format */

import axios from 'axios';
import { Config } from 'config';

export const getBookmarks = (accessToken) => {
	return axios.get(`${Config.MyDNV.azureFnPath}/getBookmarks`, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
	});
};

export const getMyDNVBookmarks = (accessToken) => {
	return axios.get(`${Config.MyDNV.azureFnPath}/getMyDNVBookmarks`, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		},
	});
};

export const postBookmark = (args) => {
	return axios.post(`${Config.MyDNV.azureFnPath}/postBookmark`, args.webpage, {
		headers: {
			Authorization: `Bearer ${args.token}`,
			'Content-Type': 'application/json',
		},
	});
};

export const deleteBookmark = (args) => {
	return axios.post(`${Config.MyDNV.azureFnPath}/deleteBookmark`, args.webpage, {
		headers: {
			Authorization: `Bearer ${args.token}`,
			'Content-Type': 'application/json',
		},
	});
};

export const checkUserBookmark = (args) => {
	return axios.post(`${Config.MyDNV.azureFnPath}/checkUserBookmark`, args.webpage, {
		headers: {
			Authorization: `Bearer ${args.token}`,
			'Content-Type': 'application/json',
		},
	});
};

export const updateBookmarkPosition = (args) => {
	return axios.put(
		`${Config.MyDNV.azureFnPath}/updateBookmarkPosition`,
		{ order_old: args.positionChanges.oldOrder, order_new: args.positionChanges.newOrder },
		{
			headers: {
				Authorization: `Bearer ${args.token}`,
				'Content-Type': 'application/json',
			},
		}
	);
};
