import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    useFloating,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
} from "@floating-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { icons } from "icons";

export const WaitForEmailVerification = (props) => {
    const { message } = props;

    const { refs, context } = useFloating({
        open: props.isOpen,
        onOpenChange: props.setModalOpen,
    });
    const click = useClick(context);
    const role = useRole(context);
    const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });
    const { getFloatingProps } = useInteractions([click, role, dismiss]);

    const [isWaiting, setIsWaiting] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        switch (message) {
            case "timeout":
                setIsWaiting(false);
                setIsVerified(false);
                setIsTimeout(true);
                break;
            case "verified":
                setIsWaiting(false);
                setIsTimeout(false);
                setIsVerified(true);
                break;

            default:
                setIsTimeout(false);
                setIsVerified(false);
                setIsWaiting(true);
                break;
        }
    }, [message]);

    const Waiting = () => {
        return (
            <>
                <p>Your email address is currently being verified.</p>
                <div className="waiting">
                    <CircularProgress size="83px" style={{ color: "black" }} />
                </div>
                <p>Please wait a moment.</p>
            </>
        );
    };
    const Verified = () => {
        return (
            <>
                <p>Your email address was successfully verified, and your MyDNV account is now ready.</p>
                <div className="verified">
                    <FontAwesomeIcon icon={`fa-solid fa-circle-check`} />
                </div>
                <p>Enjoy your personalized DNV.org experience. We&apos;re excited to have you on board!</p>
                <p>
                    <button
                        onClick={() => props.setModalOpen("close-wait-for-email-verification-modal")}
                        className="btn continue"
                    >
                        Continue
                    </button>
                </p>
            </>
        );
    };
    const Timeout = () => {
        return (
            <>
                <p>Your email verification link has expired.</p>
                <div className="expired">
                    <FontAwesomeIcon icon={`fa-solid fa-circle-xmark`} />
                </div>
                <p>Please request a new one to complete the verification process.</p>
                <p>
                    <button onClick={() => props.setModalOpen("resend-verification-email")} className="btn resend">
                        Resend verification email
                    </button>
                </p>
            </>
        );
    };

    return (
        <>
            <FloatingPortal>
                {props.isOpen && (
                    <FloatingOverlay className="modal-overlay" lockScroll>
                        <FloatingFocusManager context={context}>
                            <div className="tall-modal verify-email" ref={refs.setFloating} {...getFloatingProps()}>
                                <div className="close-btn-wrapper">
                                    <button onClick={() => props.setModalOpen("sign-out")} className="modal-close">
                                        <FontAwesomeIcon icon={icons.solid.faXmark} />
                                    </button>
                                </div>
                                <h2>Email verification</h2>
                                {isTimeout ? <Timeout /> : isVerified ? <Verified /> : <Waiting />}
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                )}
            </FloatingPortal>
        </>
    );
};
