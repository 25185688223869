/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

const getProperty = (object, name) => {
    if (object && object.hasOwnProperty(name)) {
        return object[name];
    }
}
export { getProperty };
