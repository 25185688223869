import axios from 'axios';
import { Config } from 'config';

export const getUserIdentity = (args) => {
    return axios.get(`${Config.MyDNV.azureFnPath}/getUserIdentity`, {
        validateStatus: function (status) {
            return (status >= 200 && status < 300) || status === 403; // default + 403
        },
        headers: {
            Authorization: `Bearer ${args.token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const postVerifyEmailAddress = (args) => {
    return axios.post(`${Config.MyDNV.azureFnPath}/postVerifyEmailAddress`, args.body, {
        headers: {
            Authorization: `Bearer ${args.token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const postRequestVerificationEmail = (args) => {
    return axios.post(
        `${Config.MyDNV.azureFnPath}/postRequestVerificationEmail`,
        {
            Type: args.actionType,
        },
        {
            headers: {
                Authorization: `Bearer ${args.token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};
