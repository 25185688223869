/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

/**
 * @param {string} fieldType
 */
const getShortType = (fieldType) => fieldType.split('.')[1];

export { getShortType };
