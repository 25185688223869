/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

// named "selected" regular icons in the kit
import {
    faAnchor,
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowLeftFromLine,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faAward,
    faBars,
    faBasketball,
    faBasketballHoop,
    faBookmark,
    faBookOpen,
    faBriefcase,
    faBuilding,
    faBuildingColumns,
    faBuildingFlag,
    faBullhorn,
    faBus,
    faBusSimple,
    faCalendarDays,
    faCaretUp,
    faCars,
    faChartMixed,
    faCheck,
    faCheckToSlot,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faChildReaching,
    faCircle,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleDot,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClipboardList,
    faClipboardMedical,
    faClock,
    faCloudArrowUp,
    faComment,
    faCommentDots,
    faComments,
    faCompassDrafting,
    faCopy,
    faDesktop,
    faDiamondExclamation,
    faDownload,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faEye,
    faFaucetDrip,
    faFileCheck,
    faFileInvoiceDollar,
    faFilePdf,
    faFireExtinguisher,
    faFlowerTulip,
    faFutbol,
    faGavel,
    faGear,
    faGlobe,
    faHand,
    faHandHoldingSeedling,
    faHandshake,
    faHeart,
    faHelmetSafety,
    faHouseChimneyWindow,
    faHouseHeart,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMapLocation,
    faMapLocationDot,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faMoneyBill,
    faMoneyBills,
    faMountainSun,
    faPaw,
    faPeopleGroup,
    faPeopleLine,
    faPersonBiking,
    faPersonHiking,
    faPersonSwimming,
    faPersonWalking,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRightFromBracket,
    faRoadBarrier,
    faScaleBalanced,
    faShareNodes,
    faShield,
    faShieldCheck,
    faSignHanging,
    faSirenOn,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faStar,
    faStore,
    faSuitcase,
    faTankWater,
    faTrafficCone,
    faTrafficLightGo,
    faTrain,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTreeDeciduous,
    faTriangleExclamation,
    faUniversalAccess,
    faUser,
    faVideo,
    faWineGlassEmpty,
    faXmark,
    // } from '@awesome.me/kit-f6a7b16729/icons/classic/regular'
} from "@awesome.me/kit-5f107d6f8b/icons/classic/regular";

const regular = {
    faAnchor,
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowLeftFromLine,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faAward,
    faBars,
    faBasketball,
    faBasketballHoop,
    faBookmark,
    faBookOpen,
    faBriefcase,
    faBuilding,
    faBuildingColumns,
    faBuildingFlag,
    faBullhorn,
    faBus,
    faBusSimple,
    faCalendarDays,
    faCaretUp,
    faCars,
    faChartMixed,
    faCheck,
    faCheckToSlot,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faChildReaching,
    faCircle,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleDot,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClipboardList,
    faClipboardMedical,
    faClock,
    faCloudArrowUp,
    faComment,
    faCommentDots,
    faComments,
    faCompassDrafting,
    faCopy,
    faDesktop,
    faDiamondExclamation,
    faDownload,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEnvelopeOpenText,
    faEye,
    faFaucetDrip,
    faFileCheck,
    faFileInvoiceDollar,
    faFilePdf,
    faFireExtinguisher,
    faFlowerTulip,
    faFutbol,
    faGavel,
    faGear,
    faGlobe,
    faHand,
    faHandHoldingSeedling,
    faHandshake,
    faHeart,
    faHelmetSafety,
    faHouseChimneyWindow,
    faHouseHeart,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMapLocation,
    faMapLocationDot,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faMoneyBill,
    faMoneyBills,
    faMountainSun,
    faPaw,
    faPeopleGroup,
    faPeopleLine,
    faPersonBiking,
    faPersonHiking,
    faPersonSwimming,
    faPersonWalking,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRightFromBracket,
    faRoadBarrier,
    faScaleBalanced,
    faShareNodes,
    faShield,
    faShieldCheck,
    faSignHanging,
    faSirenOn,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faStar,
    faStore,
    faSuitcase,
    faTankWater,
    faTrafficCone,
    faTrafficLightGo,
    faTrain,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTreeDeciduous,
    faTriangleExclamation,
    faUniversalAccess,
    faUser,
    faVideo,
    faWineGlassEmpty,
    faXmark,
};

export { regular };
