import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { regular, solid } from "icons";
import { LoginSignupModal } from "mydnv/components/call-to-action-subscribe/login-signup-modal";
import { useAuth } from "cms/auth/auth/hooks/useAuth";
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { Config } from "config";
import * as subscribeService from "../../../mydnv/services/subscription-service";
import { getAccessToken } from "mydnv/utils/access-token";

const SubscribeButton = (props) => {
    const { webpage } = useSimpliCity();
    const { setActionType, webpageGuid, setSubtopic } = props;
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const loginRequest = Config.MyDNV.loginRequest;

    const [subscribed, setSubscribed] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [subscriptionTopic, setSubscriptionTopic] = useState("");
    const isLoggedIn = useIsAuthenticated();

    const request = useMemo(() => {
        return {
            ...loginRequest,
            account: account,
        };
    }, [account, loginRequest]);

    let wpGuid = useMemo(() => {
        if (webpageGuid) {
            // advisories/stories/news pages pass in the guid directly from config instead
            return webpageGuid;
        } else {
            return webpage.guid;
        }
    }, [webpage, webpageGuid]);

    const fetchSubscriptions = useCallback(
        async (args) => {
            let response = await subscribeService.getSubscriptionTopics(args);
            // setSubscriptions(response.data);
            const topic = response.data.filter((s) => s.guid === webpageGuid);
            if (topic.length > 0) {
                setSubscriptionTopic(topic[0].name);
                setSubtopic(topic[0].name);
            }
            // console.log(response.data);
        },
        [setSubtopic, webpageGuid]
    );

    const getUserSubscribe = useCallback(
        async (args) => {
            let response = await subscribeService.getUserSubscribe(args);
            setSubscribed(response.data);
        },
        [setSubscribed]
    );

    const subscribeClicked = () => {
        if (isLoggedIn) {
            getAccessToken(
                request,
                instance,
                handleSubscribe,
                { guid: wpGuid },
                {
                    key: "signin-redirect-action",
                    value: "subscribe",
                }
            );
        } else {
            setIsLoginModalOpen(true);
        }
    };

    const handleModal = (isOpen) => {
        setIsLoginModalOpen(isOpen);
    };

    const handleSubscribe = useCallback(
        async (args) => {
            await subscribeService
                .postTopicSubscribe(args)
                .then(() => {
                    setSubscribed(true);
                    setActionType("subscribed");
                    // console.log(res);
                })
                .catch(() => {
                    // console.log(err);
                    setActionType("error");
                });
        },
        [setActionType, setSubscribed]
    );

    const handleUnsubscribe = useCallback(
        async (args) => {
            await subscribeService
                .postTopicUnsubscribe(args)
                .then(() => {
                    setSubscribed(false);
                    setActionType("unsubscribed");
                    // console.log(res);
                })
                .catch(() => {
                    // console.log(err);
                    setActionType("error");
                });
        },
        [setActionType]
    );

    const showButton = () => {
        const showBtnlist = Config.MyDNV.showSubscribeButtonList.toString().split(",");

        // 'engagement opportunities' & 'significant applications list' should show 'subscribe' button and no follow button, everything else should show follow button
        return showBtnlist.includes(wpGuid);
    };

    useEffect(() => {
        fetchSubscriptions();
        // on page load, if user is being redirected from B2C login page
        if (isLoggedIn) {
            if (localStorage.getItem("signin-redirect-action") == "subscribe") {
                getAccessToken(
                    request,
                    instance,
                    handleSubscribe,
                    { guid: wpGuid },
                    {
                        key: "signin-redirect-action",
                        value: "subscribe",
                    }
                );
            } else if (localStorage.getItem("signin-redirect-action") == "unsubscribe") {
                getAccessToken(
                    request,
                    instance,
                    handleUnsubscribe,
                    { guid: wpGuid },
                    {
                        key: "signin-redirect-action",
                        value: "unsubscribe",
                    }
                );
            } else {
                getAccessToken(request, instance, getUserSubscribe, { guid: wpGuid }, null);
            }
        }
    }, [
        fetchSubscriptions,
        getUserSubscribe,
        handleSubscribe,
        handleUnsubscribe,
        instance,
        isLoggedIn,
        request,
        webpage,
        wpGuid,
    ]);

    return (
        <>
            {showButton() && subscribed && (
                <button
                    className="subscribe active"
                    onClick={() => {
                        getAccessToken(
                            request,
                            instance,
                            handleUnsubscribe,
                            { guid: webpageGuid },
                            {
                                key: "signin-redirect-action",
                                value: "unsubscribe",
                            }
                        );
                    }}
                >
                    Unsubscribe from {subscriptionTopic.toLowerCase()} <FontAwesomeIcon icon={solid.faStar} />
                    {/* <FontAwesomeIcon icon={solid.faStar} /> */}
                </button>
            )}

            {showButton() && !subscribed && (
                <button className="subscribe" onClick={subscribeClicked}>
                    Subscribe to {subscriptionTopic.toLowerCase()} <FontAwesomeIcon icon={regular.faStar} />
                    {/* <FontAwesomeIcon icon={solid.faStar} /> */}
                </button>
            )}

            <LoginSignupModal isOpen={isLoginModalOpen} setModalOpen={handleModal} action={"subscribe"} />
        </>
    );
};

export { SubscribeButton };
