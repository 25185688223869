/** @format */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "icons";
import { get } from "lodash";

const FollowedInfoCard = (props) => {
    const { type, handleDismiss, subTopic } = props;

    const onClose = () => {
        handleDismiss(null);
    };

    const getCssClass = () => {
        switch (type) {
            case "followed":
            case "bookmarked":
            case "subscribed":
                return "followed";
            case "unfollowed":
            case "unbookmarked":
            case "unsubscribed":
                return "unfollowed";
            case "error":
                return "error";
            default:
                return "";
        }
    };

    const getManageSubscriptionsLink = () => `${window.location.origin}/mydnv`;

    const getManageFollowLink = () => `${window.location.origin}/mydnv?t=followings`;

    const getManageBookmarksLink = () => `${window.location.origin}/mydnv?t=bookmarks`;

    return (
        <div className={"follow-box " + getCssClass()}>
            <div className="iconBox">
                {<FontAwesomeIcon icon={solid.faCircleCheck} />}
                {<FontAwesomeIcon icon={solid.faCircleExclamation} />}
                {type === "error" && <FontAwesomeIcon icon={solid.faCircleXmark} />}
            </div>
            <div className="msgBox">
                {type === "followed" && <h4>Page followed</h4>}
                {type === "unfollowed" && <h4>Page unfollowed</h4>}
                {type === "subscribed" && <h4>Subscribed</h4>}
                {type === "unsubscribed" && <h4>Unsubscribed</h4>}
                {type === "bookmarked" && <h4>Page bookmarked</h4>}
                {type === "unbookmarked" && <h4>Bookmark removed</h4>}

                {type === "followed" && (
                    <p className="follow-msg">You&apos;ll receive email notifications for page updates.</p>
                )}
                {type === "unfollowed" && (
                    <p className="follow-msg">You&apos;ll no longer receive email notifications for page updates.</p>
                )}
                {type === "subscribed" && (
                    <p className="follow-msg">You&apos;ll receive email updates for new {subTopic}.</p>
                )}
                {type === "unsubscribed" && (
                    <p className="follow-msg">You&apos;ve unsubscribed from {subTopic} updates.</p>
                )}

                {/* {type === "subscribe-news"  && (
                    <p className="follow-msg">You&apos;ll receive email notifications for new News posts.</p>
                )}
                {type === "subscribe-news"  && (
                    <p className="follow-msg">You&apos;ve unsubscribed from News updates.</p>
                )}
                {type === "subscribe-advisories"  && (
                    <p className="follow-msg">You&apos;ll receive email updates for new Advisories.</p>
                )}
                {type === "subscribe-advisories"  && (
                    <p className="follow-msg">You&apos;ve unsubscribed from Advisories updates.</p>
                )}
                {type === "subscribe-council"  && (
                    <p className="follow-msg">You&apos;ll receive email updates for new Council documents.</p>
                )}
                {type === "subscribe-council"  && (
                    <p className="follow-msg">You&apos;ve unsubscribed from Council document updates.</p>
                )}
                {type === "subscribe-stories"  && (
                    <p className="follow-msg">You&apos;ll receive email updates for new Stories.</p>
                )}
                {type === "subscribe-stories"  && (
                    <p className="follow-msg">You&apos;ve unsubscribed from Stories updates.</p>
                )}
                {type === "subscribe-alerts"  && (
                    <p className="follow-msg">You&apos;ll receive email updates for new Alerts.</p>
                )}
                {type === "subscribe-alerts"  && (
                    <p className="follow-msg">You&apos;ve unsubscribed from Alerts updates.</p>
                )} */}
                {type === "error" && <p className="follow-msg">We were unable to process your request.</p>}
                <p>
                    {type && type.indexOf("follow") > -1 && <a href={getManageFollowLink()}>Manage my followings</a>}
                    {type && type.indexOf("subscribe") > -1 && (
                        <a href={getManageSubscriptionsLink()}>Manage my subscriptions</a>
                    )}
                    {type && type.indexOf("bookmark") > -1 && (
                        <a href={getManageBookmarksLink()}>Manage my bookmarks</a>
                    )}
                </p>
            </div>
            <div className="btn-close">
                <button onClick={() => onClose()}>
                    <FontAwesomeIcon icon={solid.faXmark} />
                </button>
            </div>
        </div>
    );
};

export { FollowedInfoCard };
