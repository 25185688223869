import {
    useFloating,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
} from "@floating-ui/react";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "icons";
import { useAuth } from "cms/auth/auth/hooks/useAuth";

export const LoginSignupModal = (props) => {
    const { action } = props;

    const { refs, context } = useFloating({
        open: props.isOpen,
        onOpenChange: props.setModalOpen,
    });
    const click = useClick(context);
    const role = useRole(context);
    const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });
    const { getFloatingProps } = useInteractions([click, role, dismiss]);
    const { instance } = useMsal();

    //  const debug = (webpage, model, error) => {
    //     console.log(webpage);
    //     console.log(model);
    //     console.log(error);
    // };

    const requestScopes = {
        scopes: ["https://dnvorgb2c.onmicrosoft.com/dnv-b2c-sample-api/tasks.read"],
    };

    const SignInRequest = {
        scopes: requestScopes.scopes,
        authority: "https://dnvorgb2c.b2clogin.com/dnvorgb2c.onmicrosoft.com/B2C_1_SignIn",
    };

    const SignUpRequest = {
        scopes: requestScopes.scopes,
        authority: "https://dnvorgb2c.b2clogin.com/dnvorgb2c.onmicrosoft.com/B2C_1_SignUp",
    };

    const login = (request) => {
        // debug(webpage, model, error);
        instance.loginRedirect(request).catch((e) => {
            console.log(e);
        });
        // log session storage
        localStorage.setItem("signin-redirect-action", action);
    };

    return (
        <>
            <FloatingPortal>
                {props.isOpen && (
                    <FloatingOverlay className="modal-overlay" lockScroll>
                        <FloatingFocusManager context={context}>
                            <div className="modal" ref={refs.setFloating} {...getFloatingProps()}>
                                <div className="close-btn-wrapper">
                                    <button onClick={() => props.setModalOpen(false)} className="modal-close">
                                        <FontAwesomeIcon icon={icons.solid.faXmark} />
                                    </button>
                                </div>
                                <h2>Create a MyDNV account</h2>

                                <p>
                                    Personalize your experience on our website with a MyDNV account. Access features
                                    like bookmarks, subscriptions, and more to stay informed and engaged with municipal
                                    services.
                                </p>
                                <div className="button-wrapper">
                                    <button className="btn" onClick={() => login(SignUpRequest)}>
                                        Sign up
                                    </button>
                                    or
                                    <button className="btn outline" onClick={() => login(SignInRequest)}>
                                        Log in
                                    </button>
                                </div>
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                )}
            </FloatingPortal>
        </>
    );
};
