import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

export const WhenSignedIn = (props) => {
    const { children } = props;

    return (
        <>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate></UnauthenticatedTemplate>
        </>
    );
};

export const WhenSignedOut = (props) => {
    const { children } = props;

    return (
        <>
            <AuthenticatedTemplate></AuthenticatedTemplate>
            <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
        </>
    );
};
