/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getPlatformConfig } from 'cms/shared/share-button/share-platform-config'

const ShareItem = (props) => {

    const { variant, type, page, onClick, onClose } = props
    const { url } = page
    const platform = getPlatformConfig(type)
    const { icon, label } = platform || {}
    const cls = (variant === 'primary') ? "share-link-primary" : "share-link"

    const handleClick = (event) => {
        event.preventDefault()
        if (onClick) {
            onClick()
        }
        platform.handler(page)
        onClose()
    }

    return (
        <li>
            <a
                href={url || "#"}
                className={cls}
                aria-label={`Share on ${label}`}
                onClick={handleClick}
            >
                <FontAwesomeIcon
                    className="social-icon"
                    icon={icon}
                    aria-hidden="true"
                />
                <span className="social-icon-label">{label}</span>
            </a>
        </li>
    )
}

export { ShareItem }
