/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { getFields, getFirstValueFromField } from "cms/fields/_helpers/helpers";

const primary_dates = [
    { type: 'model.alert', field: 'updated_at'},
    { type: 'model.news', field: 'date'},
    { type: 'model.notice', field: 'date'},
    { type: 'model.event', field: 'event_date'},
    { type: 'model.meeting', field: 'meeting_date'},
    { type: 'model.story', field: 'date'},
];

export const getPrimaryDateFieldName = (model) => {
    if (!model) {
        return;
    }
    const { type } = model;
    const primary_data = primary_dates.find((element) => element.type === type);
    if (!primary_data) {
        return;
    }
    return primary_data.field;
};

export const hasPrimaryDate = (model) => {
    if (!model) {
        return false;
    }
    const name = getPrimaryDateFieldName(model);
    return !!name;
}

export const getPrimaryDate = (model) => {
    if (!model) {
        return;
    }
    const name = getPrimaryDateFieldName(model);
    const fields = getFields(model);
    const field = fields[name];
    const date = getFirstValueFromField(field);
    return date;
}
