/** @format */

import axios from 'axios';
import { Config } from 'config';

export const postTopicSubscribe = (args) => {
    return axios.post(`${Config.MyDNV.azureFnPath}/postTopicSubscribe`, args, {
        headers: {
            Authorization: `Bearer ${args.token}`,
            'Content-Type': 'application/json',
        },
    });
};

export const postTopicUnsubscribe = (args) => {
    return axios.post(
        `${Config.MyDNV.azureFnPath}/postTopicUnsubscribe`,
        args,
        {
            headers: {
                Authorization: `Bearer ${args.token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getSubscriptionTopics = (args) => {
    return axios.get(
        `${Config.MyDNV.azureFnPathPublic}/getSubscriptionTopics?code=npFzu3KliaEfR6W3rfrXjdcq7fmtjsEd6vL2sJ7lnOkiAzFu159vqg%3D%3D`,
        {
            headers: {
                Authorization: `Bearer ${args}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            },
        }
    );
};

export const getUserSubscribe = (args) => {
    return axios.post(
        `${Config.MyDNV.azureFnPath}/getUserSubscribe`,
        { guid: args.guid },
        {
            headers: {
                Authorization: `Bearer ${args.token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};

export const getUserSubscribedTopics = (token) => {
    return axios.get(`${Config.MyDNV.azureFnPath}/getUserSubscribedTopics`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
    });
};

export const updateSubscriptionsList = (token, subscriptionIds) => {
    return axios.post(
        `${Config.MyDNV.azureFnPath}/updateSubscriptionsList`,
        subscriptionIds,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );
};
