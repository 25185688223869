export const getAccessToken = (request, instance, callback, callbackObj, storageObj) => {
    instance
        .acquireTokenSilent(request)
        .then((response) => {
            callback({ token: response.accessToken, ...callbackObj });
            if (storageObj !== null && localStorage.getItem(storageObj.key) === storageObj.value) {
                localStorage.removeItem(storageObj.key);
            }
        })
        .catch((e) => {
            // store what's happening before redirect, see login-signup-modal
            if (storageObj !== null) {
                localStorage.setItem(storageObj.key, storageObj.value);
            }

            instance.acquireTokenRedirect(request).catch((error) => console.error(error));
            console.error(e);
        });
};
