import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    useFloating,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
} from "@floating-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "icons";
import { solid } from "icons";
const { faEnvelopeOpenText } = solid;

export const VerifyEmailAddress = (props) => {
    const { emailAddress } = props;

    const { refs, context } = useFloating({
        open: props.isOpen,
        onOpenChange: props.setModalOpen,
    });
    const click = useClick(context);
    const role = useRole(context);
    const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });
    const { getFloatingProps } = useInteractions([click, role, dismiss]);

    return (
        <>
            <FloatingPortal>
                {props.isOpen && (
                    <FloatingOverlay className="modal-overlay" lockScroll>
                        <FloatingFocusManager context={context}>
                            <div className="tall-modal verify-email" ref={refs.setFloating} {...getFloatingProps()}>
                                <div className="close-btn-wrapper">
                                    <button onClick={() => props.setModalOpen("sign-out")} className="modal-close">
                                        <FontAwesomeIcon icon={icons.solid.faXmark} />
                                    </button>
                                </div>
                                <h2>Verify your email address</h2>
                                <p>We&apos;ve sent a verification link to:</p>

                                <div className="email-address">
                                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                    {emailAddress}
                                </div>
                                <p>Check your inbox and click the link to complete your MyDNV account registration.</p>
                                <h3>Didn&apos;t receive the email?</h3>
                                <ul>
                                    <li>Check your spam or junk folder</li>
                                    <li>Confirm you entered the correct email address</li>
                                </ul>
                                <div className="button-wrapper">
                                    <button
                                        className="btn btn-link"
                                        onClick={() => props.setModalOpen("resend-verification-email")}
                                    >
                                        Resend verification email
                                    </button>
                                    <br />
                                    <button
                                        className="btn btn-link"
                                        onClick={() => props.setModalOpen("sign-out-sign-up")}
                                    >
                                        Update my email address
                                    </button>
                                </div>
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                )}
            </FloatingPortal>
        </>
    );
};
