/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React, { useState, useMemo } from "react";
import { LargeFeedFilter } from "cms/shared/feeds/large";
import { FeedbackHelpful } from "components/feedback-helpful";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { SubscribeButton } from "components/page-title/subscribe-button";
import { FollowedInfoCard } from "components/page-title/followed-infocard/followed-infocard";
import { WhenSignedIn } from "mydnv/components/sign-in/sign_in_out_util";
import { BookmarkButton } from "components/page-title/bookmark-button";
import { ShareButton } from "cms/shared/share-button";
import { Config } from "config";

const News = () => {
    const { webpage, model } = useSimpliCity();

    const type = "model.news";
    const [subTopic, setSubtopic] = useState("");

    // determines what message / style to show in 'info card' after follow / subscribe / bookmark buttotn clicked
    const [actionType, setActionType] = useState(null);

    const wpGuid = Config.MyDNV.webpageGuidsList.news;
    const wpObj = useMemo(() => {
        return {
            guid: wpGuid,
            title: "News",
            published_date: null,
            updated_date: null,
        };
    }, [wpGuid]);

    return (
        <main>
            <WhenSignedIn>
                {actionType !== null && (
                    <FollowedInfoCard type={actionType} handleDismiss={setActionType} subTopic={subTopic} />
                )}
            </WhenSignedIn>

            <h1 style={{ marginTop: "16px" }}>News</h1>

            {/* Share bar */}
            <div className="nav-container">
                <div>{/* <LastModified className="modified-time-nav" model={model} /> */}</div>
                <div className="buttons-container">
                    <SubscribeButton
                        subscriptionTopic="News"
                        className="subscribe-btn-nav"
                        setActionType={setActionType}
                        webpageGuid={wpGuid}
                        setSubtopic={setSubtopic}
                    ></SubscribeButton>

                    <BookmarkButton setActionType={setActionType} wpObj={wpObj}></BookmarkButton>

                    <ShareButton className="share-btn-nav" />
                </div>
            </div>
            <div className="last-updated-rule"></div>

            {/* <Layout_TwoColumnSideNav /> */}

            <LargeFeedFilter type={type} enableBar={true} />

            <FeedbackHelpful model={model} webpage={webpage} />
        </main>
    );
};

export { News };
