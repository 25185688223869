/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React, { useState, useMemo } from "react";
import { LargeFeedFilter } from "cms/shared/feeds/large";
import { ShareButton } from "cms/shared/share-button";
import { SubscribeButton } from "components/page-title/subscribe-button";
import { BookmarkButton } from "components/page-title/bookmark-button";
import { FollowedInfoCard } from "components/page-title/followed-infocard/followed-infocard";
import { WhenSignedIn } from "mydnv/components/sign-in/sign_in_out_util";

import { Config } from "config";

const Alerts = () => {
    const type = "model.alert";

    // determines what message / style to show in 'info card' after follow / subscribe / bookmark buttotn clicked
    const [actionType, setActionType] = useState(null);
    const [subTopic, setSubtopic] = useState("");
    const wpGuid = Config.MyDNV.webpageGuidsList.alerts;

    const wpObj = useMemo(() => {
        return {
            guid: wpGuid,
            title: "Alerts",
            published_date: null,
            updated_date: null,
        };
    }, [wpGuid]);

    return (
        <main>
            <WhenSignedIn>
                {actionType !== null && (
                    <FollowedInfoCard type={actionType} handleDismiss={setActionType} subTopic={subTopic} />
                )}
            </WhenSignedIn>
            <h1 style={{ marginTop: "16px" }}>Alerts</h1>
            <div className="nav-container justify-end">
                <div className="buttons-container">
                    <SubscribeButton
                        subscriptionTopic="Alerts"
                        setActionType={setActionType}
                        webpageGuid={wpGuid}
                        setSubtopic={setSubtopic}
                        className="subscribe-btn-nav"
                    ></SubscribeButton>
                    <BookmarkButton setActionType={setActionType} wpObj={wpObj}></BookmarkButton>
                    <ShareButton className="share-btn-nav" />
                </div>
            </div>
            <div className="last-updated-rule"></div>
            <LargeFeedFilter type={type} enableBar={true} />
        </main>
    );
};

export { Alerts };
