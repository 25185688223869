/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { SimpliCityContextProvider } from "cms/context";
import { SimpliCityAuthProvider } from "cms/auth/auth/simplicity-auth-context";
import { UserWayPlugin } from "cms/integration/userway/userway";
import { LoadWebsite } from "cms/shared/load-website";
import { RouterProvider } from "react-router-dom";
import { router } from "router/router";
import { Config } from "config";
import { CmsConfig } from "cms/config";
import { Logger } from "cms/utils/logger";
import { theme } from "theme";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SkipDuringPrerender } from "cms/shared/hyrdation/avoid-prerender";
import "styles/app-styles";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { MyDNVProvider } from "mydnv/context/mydnv-context";

const SimpliCityApp = () => {
    const [error, setError] = useState();
    const [website, setWebsite] = useState();
    const [settings, setSettings] = useState();
    const recaptcha_site_key = Config.recaptcha_site_key || CmsConfig.recaptcha_site_key;

    const msalConfig = {
        auth: {
            clientId: Config.MyDNV.msal.clientId, // "ca9f583e-ba6d-4a63-a612-647fbe601d06", // This is the ONLY mandatory field that you need to supply.
            authority: Config.MyDNV.msal.authority, // Defaults to "https://login.microsoftonline.com/common"
            // Because https://dnvorgb2c.b2clogin.com/dnvorgb2c.onmicrosoft.com/b2c_1_signin/v2.0/.well-known/openid-configuration must exist
            knownAuthorities: [Config.MyDNV.msal.knownAuthorities], // Mark your B2C tenant's domain as trusted.
            redirectUri: Config.MyDNV.msal.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
            postLogoutRedirectUri: Config.MyDNV.msal.postLogoutRedirectUri, // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: Config.MyDNV.msal.cache,
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    useEffect(() => {
        // console.log(error)
        if (error) {
            Logger.error(error);
        }
    }, [error]);

    return (
        <MyDNVProvider>
        <GoogleReCaptchaProvider reCaptchaKey={recaptcha_site_key}>
            <ThemeProvider theme={theme}>
                <SimpliCityAuthProvider>
                    <MsalProvider instance={msalInstance}>
                        <LoadWebsite setError={setError} setWebsite={setWebsite} setSettings={setSettings} />
                        {/*  Show an empty skeleton while data is loading  */}
                        {website && (
                            <SimpliCityContextProvider website={website} settings={settings}>
                                {/*   The Router will pass processing context to the   */}
                                {/*   route *element* that matches the request         */}
                                <RouterProvider router={router} />
                            </SimpliCityContextProvider>
                        )}
                    </MsalProvider>
                </SimpliCityAuthProvider>
                <SkipDuringPrerender>
                    <UserWayPlugin />
                </SkipDuringPrerender>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
        </MyDNVProvider>
    );
};

SimpliCityApp.displayName = "SimpliCityApp";
export { SimpliCityApp };
