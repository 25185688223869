import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from 'icons';
import { LoginSignupModal } from 'mydnv/components/call-to-action-subscribe/login-signup-modal';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { useSimpliCity } from 'cms/hooks/use-simplicity';
import { Config } from 'config';
import * as bookmarkService from '../../../mydnv/services/bookmark-service';
import { getAccessToken } from 'mydnv/utils/access-token';
import { useMyDNVContext } from 'mydnv/context/mydnv-context';

const BookmarkButton = (props) => {
    let {bookmarks, setBookmark} = useMyDNVContext();
    const { webpage, model } = useSimpliCity();
    const { title } = model || {};

    // const { isBookmarked, handleUnbookmark } = props;
    const { setActionType, wpObj } = props;

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isLoggedIn = useIsAuthenticated();
    const loginRequest = Config.MyDNV.loginRequest;

    const [bookmarked, setBookmarked] = useState(false);

    const request = useMemo(() => {
        return {
            ...loginRequest,
            account: account,
        };
    }, [account, loginRequest]);

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    let webpageObject = useMemo(() => {
        if (wpObj !== undefined) {
            return wpObj;
        } else {
            return {
                guid: webpage.guid,
                title: title,
                published_date: webpage.created_at,
                updated_date: webpage.updated_at,
            };
        }
    }, [webpage, wpObj, title]);

    if (webpageObject === undefined && wpObj) {
        webpageObject = wpObj;
    }

    const bookmarkClicked = () => {
        if (isLoggedIn) {
            getAccessToken(
                request,
                instance,
                handleBookmark,
                { webpage: webpageObject },
                {
                    key: 'signin-redirect-action',
                    value: 'bookmark',
                }
            );
        } else {
            setIsLoginModalOpen(true);
        }
    };

    const handleModal = (isOpen) => {
        setIsLoginModalOpen(isOpen);
    };

    const getUserBookmark = useCallback(
        async (args) => {
            let response = await bookmarkService.checkUserBookmark(args);
            setBookmarked(response.data);
        },
        [setBookmarked]
    );

    const handleBookmark = useCallback(
        async (args) => {
            try {
                let response = await bookmarkService.postBookmark(args);
                setBookmarked(true);
                setActionType('bookmarked');
                setBookmark(JSON.stringify(response.data));
            } catch (error) {
                console.log(error);
                setActionType('error');
            }
        },
        [setActionType]
    );

    const handleUnbookmark = useCallback(
        async (args) => {
            try {
                let response = await bookmarkService.deleteBookmark(args);
                setBookmarked(false);
                setActionType('unbookmarked');
                setBookmark(JSON.stringify(response.data));
            } catch (error) {
                console.log(error);
                setActionType('error');
            }
        },
        [setActionType]
    );

    useEffect(() => {
        // on page load, if user is being redirected from B2C login page
        if (isLoggedIn) {
            if (localStorage.getItem('signin-redirect-action') == 'bookmark') {
                getAccessToken(
                    request,
                    instance,
                    handleBookmark,
                    { webpage: webpageObject },
                    {
                        key: 'signin-redirect-action',
                        value: 'bookmark',
                    }
                );
            } else if (localStorage.getItem('signin-redirect-action') == 'unbookmark') {
                getAccessToken(
                    request,
                    instance,
                    handleUnbookmark,
                    { webpage: webpageObject },
                    {
                        key: 'signin-redirect-action',
                        value: 'unbookmark',
                    }
                );
            } else {
                getAccessToken(request, instance, getUserBookmark, { webpage: webpageObject }, null);
            }
        }
    }, [getUserBookmark, handleBookmark, handleUnbookmark, instance, isLoggedIn, request, webpage, webpageObject]);

    return (
        <>
            {!bookmarked && (
                <button className='subscribe' onClick={bookmarkClicked}>
                    Bookmark <FontAwesomeIcon icon={regular.faBookmark} />
                    {/* <FontAwesomeIcon icon={solid.faStar} /> */}
                </button>
            )}

            {bookmarked && (
                <button
                    className='subscribe active'
                    onClick={() => {
                        getAccessToken(
                            request,
                            instance,
                            handleUnbookmark,
                            { webpage: webpageObject },
                            {
                                key: 'signin-redirect-action',
                                value: 'unbookmark',
                            }
                        );
                    }}
                >
                    Remove bookmark <FontAwesomeIcon icon={solid.faBookmark} />
                    {/* <FontAwesomeIcon icon={solid.faStar} /> */}
                </button>
            )}

            <LoginSignupModal isOpen={isLoginModalOpen} setModalOpen={handleModal} action={'bookmark'} />
        </>
    );
};

export { BookmarkButton };
