/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useEffect } from "react";
import { Logger } from "cms/utils/logger";
import { LayoutWrapper } from "cms/layout/layout-wrapper";
import { useFetchWebpage } from "cms/hooks/use-fetch-webpage";
import { EnvWrapper } from "cms/auth/auth/components/env-wrapper";

const Home = () => {

    const { fetchWebpageData } = useFetchWebpage();

    useEffect(() => {
        Logger.debug("Start Load home...");
        fetchWebpageData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <EnvWrapper>
            <LayoutWrapper />
        </EnvWrapper>
    );
};

export { Home };
