/** @format */

import { SimpliCitySDK } from 'cms/sdk';
import { Logger } from 'cms/utils/logger';

export const fetchWebpage = async (token, webpageGuid) => {
	try {
		let webpage = await SimpliCitySDK.webpage.findOne(token, webpageGuid);
		return webpage;
	} catch (error) {
		Logger.error(error);
		return error;
	}
};
